<template>
    <div id="phone-container">
      <div id="phone-wrapper">
        <img id="phone-outer-layer" src="../../assets/phone-frame.png"/>
        <div id="homepage-background">
          <img id="homepage-background-image" src="../../assets/homepage-background.jpg" />
          <div id="homepage-content">
            <div class="homepage-item-top" id="homepage-item-top">
              <h1>{{ $t("HOMEPAGE_HEADER") }}</h1>
              <span>{{ $t("HOMEPAGE_CONTENT") }}</span>
            </div>
            <div id="homepage-papers" class="homepage-papers-container">
              <img class="homepage-paper" id="paper-3" src="../../assets/homepagePapers/paper3.png" style="transform-origin: 65.9% 45.2%;" />
              <img class="homepage-paper" id="paper-2" src="../../assets/homepagePapers/paper2.png" style="transform-origin: 36.1% 54.78%;" />
              <img class="homepage-paper-primary" id="paper-1" src="../../assets/homepagePapers/paper1.png" style="height: 100%; position: relative;"/>
              <div id="papers-border" style="position: absolute; height: 66.66%; width: 44%; top: 0.5%; left: 50.5%; transform: translateX(-50%);" />
            </div>
            <div class="homepage-item-bottom">
              <span>{{ $t("SCROLL_DOWN_FOR_MORE")}}</span>
              <img class="swipe-down-arrow" src="../../assets/more-arrow.png" />
            </div>
          </div>
        </div>
        <div id="phone-page-slides">
          <feature-pages :active-testimonial="activeTestimonial" />
        </div>
      </div>
    </div>
</template>

<script>
import { onMounted, inject, onUnmounted } from "vue";
import debounce from "lodash.debounce";

import FeaturePages from "@/components/common/FeaturePages";

export default {
  name: "PhoneWrapper",
  components: {
    "feature-pages": FeaturePages
  },
  props: {
    activeTestimonial: {
      type: Object,
      default: () => {}
    }
  },
  setup() {

    const global = inject("global");
    const $ = require("jquery")

    function resizePhone() {
      let maxHeight = $(window).height() * 0.65;
      let maxWidth = $(window).width() * 0.30;
      if($(window).width() <= 992) {
        maxWidth = Math.min($(window).width() * 0.70, $(window).height() * 0.50)
        maxHeight = $(window).height() * 0.65
      }
      const phoneContainer = document.getElementById("phone-page-slides")
      const phoneImg = document.getElementById("phone-outer-layer")
      const selectedTestimonialAvatar = document.getElementById("selected-testimonial-avatar")

      if(maxHeight * 676 / 1366 < maxWidth) {
        phoneImg.style.width = maxHeight * 676 / 1366 + "px"
        phoneContainer.style.width = maxHeight * 676 * 0.905 / 1366 + "px"
        selectedTestimonialAvatar.style.width = maxHeight * 676 * 0.4 / 1366 + "px"
        selectedTestimonialAvatar.style.height = maxHeight * 676 * 0.4 / 1366 + "px"
        phoneImg.style.height = maxHeight + "px"
        phoneContainer.style.height = maxHeight * 0.96 + "px"
      } else {
        phoneImg.style.height = maxWidth * 1366 / 676 + "px"
        phoneContainer.style.height = maxWidth * 1366 *0.96 / 676 + "px"
        selectedTestimonialAvatar.style.height = maxWidth * 0.4 + 'px'
        selectedTestimonialAvatar.style.width = maxWidth * 0.4 + 'px'

        phoneImg.style.width = maxWidth + "px"
        phoneContainer.style.width = maxWidth * 0.905 + "px"
      }
    }
    const debouncedPhoneResizer = debounce(resizePhone, 10)
    onMounted(() => {
      resizePhone()
      window.addEventListener("resize", debouncedPhoneResizer)
    })
    onUnmounted(() => {
      window.removeEventListener("resize", debouncedPhoneResizer)
    })
    return {
      global
    }
  }
}
</script>

<style scoped>
#phone-page-slides {
  z-index:5;
  overflow: hidden;
  position: absolute;
  top: calc(var(--vh) * 30);
  left: 50vw;
  transform: translate(-50%, -50%);
  transform-origin: 0 0;
  border-radius: 25px;
  will-change: transform;

}

#phone-outer-layer {
  z-index: 6;
  position: absolute;
  top: calc(var(--vh) * 30);
  left: 50vw;
  will-change: transform, top;
  transform: translate(-50%, -50%) translateZ(1px);
  transform-origin: 0 0;
}

#phone-wrapper {
  position: sticky;
  height: calc(var(--vh) * 100);
  overflow-x: hidden;
  top: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  will-change: transfrom;
  background: rgba(0,0,0,0);
  z-index: 9998;
  pointer-events: none;
}

#phone-container {
  position: absolute;
  width: 100vw;
  top: 0;
  left: 0;
  height: calc(var(--vh) * 1100)
}

.homepage-item-top h1 {
  font-weight: bold;
  font-size: min(60px, 6vh);
  line-height: 82px;
  /* identical to box height */

  text-align: center;
  margin: 0;
  color: #2E293D;

}
.homepage-paper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
#homepage-papers {
  position: absolute;
  height: 50%;
  top: 55%;
  left: 50%;
  transform: translate(-50%,-50%);
}
#homepage-background {
  position: relative;
  overflow: hidden;
  top: calc(var(--vh) * 30);
  transform: translateY(-50%);
  border-radius: 30px;
  will-change: transform, top;
  user-select: none;
}

#homepage-background-image {
  position: absolute;
  height: calc(var(--vh) * 100);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#homepage-content {
  position: absolute;
  box-sizing: border-box;
  width: 100vw;
  height: calc(var(--vh) * 100);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-top: min(125px, 10vh);
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.homepage-item-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homepage-item-top span {
  font-weight: 500;
  font-size: min(34px, 4vh);
  line-height: 150%;
  /* identical to box height, or 51px */

  text-align: center;
  letter-spacing: 0.002em;
  margin: 0;
  color: #BABABA;
}
.homepage-item-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;
}
.homepage-item-bottom span {
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */

  text-align: center;
  letter-spacing: 0.002em;
  margin-bottom: 8px;

  color: #2E293D;
}
.swipe-down-arrow {
  width: 22px;
  height: 26px;
  transform-origin: bottom;
  animation: swipeDownAnim 1s infinite;
}

@keyframes swipeDownAnim {
  0% { transform: scaleY(1)}
  85% {transform: scaleY(0.6)}
  100% {transform: scaleY(1)}
}

@media screen and (max-width: 992px) {

  #phone-page-slides {
    bottom: 11.6%!important;
  }

  #phone-wrapper {
    padding: 10px 30px;
  }

}
</style>