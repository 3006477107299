<template>
  <div style="z-index: 20;">
    <feature-card
        label="FEATURE_TRANSLATE_HEADER"
        content="FEATURE_TRANSLATE_CONTENT"
        :active="activeCard === 5"
        icon="translate"
        style="position: absolute; right: 28%; top: 37.5%; transform: translate(50%, -50%)"
        :past="activeCard > 5"
        @card-click="$emit('active-card-change',5)"/>
    <feature-card
        label="FEATURE_SHARE_HEADER"
        content="FEATURE_SHARE_CONTENT"
        :active="activeCard === 4"
        icon="share"
        style="position: absolute; right: 23%; top: 63%; transform: translate(50%, -50%); z-index: 50;"
        :past="activeCard > 4"
        @card-click="$emit('active-card-change',4)"/>
    <feature-card
        label="FEATURE_EDIT_HEADER"
        content="FEATURE_EDIT_CONTENT"
        :active="activeCard === 3"
        icon="edit"
        style="position: absolute; right: 28%; top: 86.5%; transform: translate(50%, -50%)"
        :past="activeCard > 3"
        @card-click="$emit('active-card-change',3)" />
    <feature-card
        label="FEATURE_SCAN_HEADER"
        content="FEATURE_SCAN_CONTENT"
        :active="activeCard === 0"
        :past="activeCard > 0"
        icon="scan"
        style="position: absolute; left: 28%; top: 37.5%; transform: translate(-50%, -50%)"

        @card-click="$emit('active-card-change',0)"/>
    <feature-card
        label="FEATURE_OCR_HEADER"
        content="FEATURE_OCR_CONTENT"
        :active="activeCard === 1"
        icon="ocr"
        style="position: absolute; left: 23%; top: 63%; transform: translate(-50%, -50%); z-index: 50;"
        :past="activeCard > 1"

        @card-click="$emit('active-card-change',1)" />
    <feature-card
        label="FEATURE_SIGNATURE_HEADER"
        content="FEATURE_SIGNATURE_CONTENT"
        :active="activeCard === 2"
        icon="signature"
        style="position: absolute; left: 28%; top: 86.5%; transform: translate(-50%, -50%)"
        :past="activeCard > 2"

        @card-click="$emit('active-card-change',2)" />
  </div>
</template>

<script>

import FeatureCard from "@/components/common/FeatureCard";

export default {
  name: "FeatureCardCollection",
  components: {
    "feature-card": FeatureCard
  },
  emits: [
      "active-card-change"
  ],
  props: {
    activeCard: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped>

</style>