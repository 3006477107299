<template>
  <div id="about">
    <div class="sticky-container">
      <div class="about-container">
        <div class="headers-wrapper" id="about-header-container">
          <div class=" header-container header-left">
            <h1>{{ $t("ABOUT_HEADER_LEFT") }}</h1>
            <span>{{ $t("ABOUT_CONTENT_LEFT") }}</span>
          </div>
          <div class="header-container header-right">
            <h1>{{ $t("ABOUT_HEADER_RIGHT") }}</h1>
            <span>{{ $t("ABOUT_CONTENT_RIGHT") }}</span>
            <store-badges style="margin-top: 1.5vh;" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import StoreBadges from "@/components/common/StoreBadges";

export default {
  name: "About",
  components: {
    "store-badges": StoreBadges
  }
}
</script>

<style scoped>
#about {
  position: relative;
  display: initial;
  height: calc(var(--vh) * 250);
  width: 100%;
  scroll-snap-align: start;
}
.sticky-container {
  height: calc(var(--vh) * 150);
  width: 100%;
  position: absolute;
  top: calc(var(--vh) * 100);
}
.about-container {
  position: sticky;
  top: 0;
  height: calc(var(--vh) * 100);
  width: 100%;
  padding: 16.5vh 0;
  box-sizing: border-box;
}
.header-container h1 {
  font-weight: bold;
  font-size: 6vh;
  line-height: min(89px, 6.85vw);
  color: #2E293D;
  margin: 0;
}

.headers-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  opacity: 0;
}

.header-container {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.badge-container {
  margin: 30px 0;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.header-left {
  align-items: flex-start;
  align-self: flex-start;
}

.header-left h1, .header-left span{
  text-align: start;
}

.header-right {
  align-items: flex-end;
  align-self: flex-end;
}

.header-right h1, .header-right span{
  text-align: end;
}

.header-container span {
  font-weight: 500;
  font-size: 2vh;
  line-height: 150%;
  letter-spacing: 0.8px;
  color: #2E293D;
}

</style>