<template>
  <generic-section height="calc(var(--vh) * 1000" id="about-features">
    <about-section />
    <div id="features-all">
      <div class="features-overlay-container">
        <div style="height: calc(var(--vh) * 50);" />
        <div id="features" style="position: absolute; top: calc(var(--vh) * 50);" />
        <div v-for="index in 7" v-bind:key="index" class="features-overlay" />
      </div>
      <div id="features-container">
        <h1 class="section-header" id="features-header">{{ $t("FEATURES_HEADER")}}</h1>
        <span class="section-text" id="features-text">{{ $t("FEATURES_CONTENT")}}</span>
        <div id="features-progress-circular" />
        <feature-card-collection @active-card-change="setScroll" :active-card="activeCard" />
      </div>
    </div>
    <phone-wrapper :active-testimonial="activeTestimonial" />
  </generic-section>
</template>

<script>
import { ref, onMounted, inject, onUnmounted, onBeforeUnmount } from "vue";
import debounce from "lodash.debounce"
import clamp from "lodash.clamp"

import listenerHelper from "@/utils/helpers/listenerHelper";
import GenericSection from "@/components/generic/GenericSection";
import FeatureCardCollection from "@/components/web/FeatureCardCollection";
import PhoneWrapper from "@/components/web/PhoneWrapper";
import About from "@/pages/web/sections/About";
import animations from "@/utils/animations";

export default {
  name: "Features",
  components: {
    "generic-section": GenericSection,
    "feature-card-collection": FeatureCardCollection,
    "phone-wrapper": PhoneWrapper,
    "about-section": About
  },
  props: {
    activeTestimonial: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const global = inject("global");

    const $ = require("jquery");

    let circularProgress;
    const activeCard = ref(-1);
    let scrollPos = 0;

    const setActiveCard = card => {
      if(activeCard.value !== card && card > -2 && card < 7) {
        activeCard.value = card;
      }
    }

    const phoneScaler = (height, width, scrollTop) => {

      const phone = document.getElementById("phone-outer-layer");
      const pageSlides = document.getElementById("phone-page-slides");
      const backgroundScaler = document.getElementById("homepage-background");

      const phoneWidth = parseFloat(phone.style.width.substring(0, phone.style.width.length - 2))
      const phoneHeight = parseFloat(phone.style.height.substring(0, phone.style.width.length - 2))

      const phoneOutsideScale = width / phoneWidth * 1.5 - 1
      const scale = (1 + Math.max(0, (height - scrollTop) /height * phoneOutsideScale ))

      if(scrollTop >= height  || phone.style.transform !== "scale(" + scale + ") translate(-50%, -50%)") {
        phone.style.transform = "scale(" + scale + ") translate(-50%, -50%)"
        backgroundScaler.style.width = phoneWidth * scale * 0.905 + "px";
        backgroundScaler.style.height = phoneHeight * scale * 0.96 + "px";
        pageSlides.style.transform = "scale(" + scale + ") translate(-50%, -50%)"
      }

      const topValue = 50 + clamp((scrollTop - height * 2) / height, 0, 1) * 13

      phone.style.top = "calc(var(--vh) * " + topValue + ")"
      backgroundScaler.style.top = "calc(var(--vh) * " + topValue + ")"
      document.getElementById("phone-page-slides").style.top = "calc(var(--vh) * " + topValue + ")"

    }

    const appScrollListener = () => {
      const height = $(window).height()
      const width = $(window).width()
      const scrollTop = (!global.state.isSafari || global.state.isMobile) ? document.scrollingElement.scrollTop : document.body.scrollTop
      const homepageTitle = document.getElementById("homepage-item-top")
      const homepageContent = document.getElementById("homepage-content")
      if(homepageTitle.style.opacity < 1 || scrollTop <= height * 1) {
        const paperThirdTranslateX = -15.9, paperThirdTranslateY = -13.7, paperThirdRotate = -13.9,
            paperSecondTranslateX = 14.3, paperSecondTranslateY = -22.7, paperSecondRotate = 22.84;
        const ratio = clamp((scrollTop - height * 0.5) / (height * 0.5), 0, 1)
        homepageTitle.style.opacity = clamp((height - scrollTop) / height, 0, 1)
        document.getElementById("paper-3").style.transform = `translate(${paperThirdTranslateX * ratio}%,${paperThirdTranslateY * ratio}%) rotate(${paperThirdRotate * ratio}deg)`
        document.getElementById("paper-2").style.transform = `translate(${paperSecondTranslateX * ratio}%,${paperSecondTranslateY * ratio}%) rotate(${paperSecondRotate * ratio}deg)`
        document.getElementById("papers-border").style.border = `2.5px solid rgba(98, 125, 255, ${ Math.pow(ratio, 5)})`
        homepageContent.style.backgroundColor = `rgba(98, 125, 255, ${ 0.4 * Math.pow(ratio, 3)})`
      }

      if(scrollTop >= height && scrollPos < height) {
        const aboutHeadersContainer = document.getElementById("about-header-container")
        animations.appearUp(aboutHeadersContainer)
      } else if(scrollTop < height && scrollPos >= height) {
        const aboutHeadersContainer = document.getElementById("about-header-container")
        animations.disappearDown(aboutHeadersContainer)
      }

      if(scrollTop >= height * 2.5 && scrollPos < height * 2.5) {
        const featuresHeader = document.getElementById("features-header")
        const featuresText = document.getElementById("features-text")
        animations.appearUp(featuresHeader)
        animations.appearUp(featuresText)
      } else if(scrollTop < height * 2.5 && scrollPos >= height * 2.5) {
        const featuresHeader = document.getElementById("features-header")
        const featuresText = document.getElementById("features-text")
        animations.disappearDown(featuresHeader)
        animations.disappearDown(featuresText)
      }

      if(scrollTop >= height * 10 && scrollPos < height * 10) {
        const testimonialsHeaderContainer = document.getElementById("testimonials-header-container")
        animations.appearUp(testimonialsHeaderContainer)
      } else if(scrollTop < height * 10 && scrollPos >= height * 10) {
        const testimonialsHeaderContainer = document.getElementById("testimonials-header-container")
        animations.disappearDown(testimonialsHeaderContainer)
      }

      if(scrollTop >= height * 10.1 && scrollPos < height * 10.1) {
        const blogHeaderContainer = document.getElementById("blog-header-container")
        animations.appearUp(blogHeaderContainer)
      } else if(scrollTop < height * 10.1 && scrollPos >= height * 10.1) {
        const blogHeaderContainer = document.getElementById("blog-header-container")
        animations.disappearDown(blogHeaderContainer)
      }

      scrollPos = scrollTop

      const containerHeight = document.getElementById("phone-page-slides").clientHeight;
      const translatePixel = clamp((scrollTop - 2 * height) * containerHeight / height, 0,containerHeight * 6) + clamp((scrollTop - 9 * height) * containerHeight / height, 0,containerHeight)

      const homepageBackground = document.getElementById("homepage-background-image")
      const homepagePapers = document.getElementById("homepage-papers")

      homepageBackground.style.transform = `translateX(-50%) translateY(calc(-50% - ${translatePixel}px))`
      homepagePapers.style.transform = `translateX(-50%) translateY(calc(-50% - ${translatePixel}px))`



      document.getElementById("feature-pages").style.transform = `translateY(-${translatePixel}px)`

      setActiveCard( Math.floor((scrollTop - height * 3 + 1) / height))
      const percent = 0.25 * 0.595 * clamp((scrollTop - height * 2.5) / (height * 0.5), 0, 1)
          + 0.25 * 0.405 * clamp((scrollTop - height * 3) / height, 0, 2)
          + 0.5 * 0.595 * clamp((scrollTop - height * 5 ) / height, 0, 1)
          + 0.25 * 0.405 * clamp((scrollTop - height * 6) / height, 0, 2)
          + 0.25 * 0.595 * clamp((scrollTop - height * 8) / height, 0, 1)
      !global.state.isMobile && circularProgress.animate(percent)

      phoneScaler(height,width,scrollTop)

      if(scrollTop >= height * 3 && scrollTop <= height * 8) {
        if((global.state.isSafari && !global.state.isMobile)) {
          // document.body.style["scroll-snap-type"] = "mandatory"
          document.body.style["scroll-snap-type"] = "y mandatory"


        } else {
          document.getElementsByTagName("html")[0].style["scroll-snap-type"] = "y mandatory"

        }
      } else {
        if((global.state.isSafari && !global.state.isMobile)) {
          document.body.style["scroll-snap-type"] = "y proximity"


        } else {
          document.getElementsByTagName("html")[0].style["scroll-snap-type"] = "none"
        }
      }

    }
    function setScroll(card) {
      const height = $(window).height()
      document.scrollingElement.scrollTop = height * 3 + card * height + 1
    }

    onMounted(() => {
      const ProgressBar = require('progressbar.js')

      circularProgress = new ProgressBar.Circle('#features-progress-circular', {
        strokeWidth: 50,
        easing: 'easeInOut',
        duration: 50,
        color: '#EBEFFF',
        trailColor: '#eee',
        trailWidth: 0.000001,
        svgStyle: {width: '100%', height: '100%'}
      });

      phoneScaler($(window).height(), $(window).width(), document.scrollingElement.scrollTop)


      if((global.state.isSafari && !global.state.isMobile)) {
        document.body.addEventListener("scroll", appScrollListener, listenerHelper.passiveIfSupported())

      } else {
        document.addEventListener("scroll", appScrollListener, listenerHelper.passiveIfSupported())

      }

      window.addEventListener("resize", debounce(() => {
        const scrollTop = (global.state.isSafari && !global.state.isMobile) ? document.body.scrollTop : document.scrollingElement.scrollTop
        phoneScaler($(window).height(), $(window).width(), scrollTop)
      }, 80))

    })
    onBeforeUnmount(() => {
      document.getElementById("features-progress-circular").textContent = ""

    })

    onUnmounted(() => {
      if ((global.state.isSafari && !global.state.isMobile)) {
        document.body.removeEventListener("scroll", appScrollListener, listenerHelper.passiveIfSupported())

      } else {
        document.removeEventListener("scroll", appScrollListener, listenerHelper.passiveIfSupported())

      }
    })
    return {
      activeCard,
      global,
      setScroll
    }
  }
}
</script>

<style scoped>

#badge-container {
  display: flex;
  justify-content: center;
  align-items: center
}

#features-all {
  position: absolute;
  width: 100vw;
  top: calc(var(--vh) * 250);
  left: 0;
  height: calc(var(--vh) * 750);

}
.features-overlay-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.features-overlay {
  scroll-snap-align: center;
  height: calc(var(--vh) * 100);
  width: 100%;
  flex: none;
}
#features-header, #features-text {
  z-index: 1;
}

#features-progress {
  position: absolute;
  top: 15%;
  width: 80%;
}
#features-progress-circular {
  position: absolute;
  top: 63%;
  left: 50%;
  width: 85vh;
  transform-origin: center;
  transform: translate(-50%, -50%) scaleX(-1);
}
#features-container {
  z-index: 9997;
  position: sticky;
  top: 0;
  height: calc(var(--vh) * 100);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  will-change: transfrom;
  background: rgba(0,0,0,0);
}

.section-header {
  margin: calc(75px + 4.5vh) 0;
  margin-bottom: 0.7vh;
  font-size: 5vh;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2e293d;
  opacity: 0;
}
.section-text {
  margin: 12px 95px 0 104px;
  margin-top: 0.7vh;
  font-size: 2.2vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.8px;
  text-align: center;
  color: #c8c8c8;
  opacity: 0;
}

.company-header {
  font-size: 3.5em;
  font-weight: bold;
  margin: 10px 0;
}
.company-subheader {
  font-size: min(2.8em,2.7vh);
  font-weight: 500;
  margin: 20px;
}
#features {
  scroll-snap-align: start;
}

@media screen and (max-width: 992px) {

  .company-header {
    margin-top: 50px;
    font-size: min(2rem, 3vh);

  }
  .company-subheader {
    margin: 10px 20px;
    font-size: min(2rem, 3vh);
  }
}
@media screen and (max-width: 992px) {
  .section-header {
    font-size: min(1.7rem, 2.8vh);
    margin: 10px 0;
  }
  #features-progress {
    top: 1%;
  }
  .company-subheader {
    display: none;
  }
  .section-text {
    font-size: 0.9rem;
  }
  #features-container {
    padding: 10px 30px;
  }

}
</style>